<template>
  <div>
    <MyHeader :title="$t('lang.menu_abnormal')" :ShowBack="true" :BackUrl="backUrl" RightIcon="search"
      @rightclick="onRightClick" />
    <van-tabs @change="onChange" v-model="mType">
      <van-tab :title="$t('lang.normal')" key="1" />
      <van-tab :title="$t('lang.abnormal')" key="2" />
    </van-tabs>
    <van-list v-model="loading" :finished="finished" :finished-text="$t('lang.listend')" :immediate-check="false"
      direction="down" @load="onLoad">
      <van-cell v-for="mData in lstData" :key="mData.id" :title="mData.eventName">
        <template #right-icon>
          <van-tag type="warning" size="medium" class="custtag">{{ (mData.showRate * 100).toFixed(2) }}%</van-tag>
          <van-tag color="#CC99CC" size="medium" class="custtag">{{
            mData.showTimes
          }}</van-tag>
          <!--
          <div class="chartAll">
            <div class="chartU" :style="'width:' + (mData.normalRate * 100).toFixed(2) + '%'">
            </div>
            <div class="Fl">
              <div class="Le">{{ (mData.normalRate * 100).toFixed(2) }}% </div>
              <div class="Re"> {{ (mData.abnormalRate * 100).toFixed(2) }}%</div>
            </div>
          </div>
          -->
        </template>
      </van-cell>
    </van-list>


    <!--div
      v-show="showHint"
      style="margin: 20px; text-align: center; display: block; color: #969799"
    >
      {{ $t("lang.nodata") }}
    </div-->
  </div>
</template>

<style>
.custtag {
  height: 24px;
  margin-left: 5px;
  min-width: 20px;
}
/*
.chartAll {
  width: 150px;
  height: 22px;
  background-color: #ff976a;
  position: relative;
}

.chartU {
  height: 100%;
  background-color: #07c160;
  text-align: left;
}

.Fl {
  position: absolute;
  left: 0px;
  top: 0px;
  color: #fff;
  width: 100%;
}

.Le {
  width: 50%;
  text-align: left;
  float: left;
}

.Re {
  width: 50%;
  text-align: right;
  float: right;
}
*/
</style>

<script>
import MyHeader from "@/components/Header.vue";
import { GetUnusual } from "@/api/index.js";
import { getSessionObj } from "@/utils/session.js";

export default {
  components: { MyHeader },
  data() {
    return {
      lstData: [],
      loading: false,
      finished: false,
      mType:"1",
      page: 1,
      backUrl: ""
      //showHint: true,
    };
  },

  mounted() {
    if (!this.$store.getters.getIsXunjianAPP)
      this.backUrl = "/total/index";
    else
      this.backUrl = "/query/appindex";
  },

  activated() {
    if (this.$route.params.p) {
      this.mType = "1";
      this.page = 1;
      this.lstData = [];
      this.Query();
    }
  },

  deactivated() {
    //console.log("deactivated");
  },

  methods: {
    onRightClick() {
      this.$router.push({
        name: "QueryForm",
        params: { title: this.$t('lang.menu_abnormal'), f: "Unusual" },
      });
    },

    onChange(index) {
      let lst = this.lstData;
      for (let i = 0; i < lst.length; i++) {
        switch (index) {
          case 0:
            lst[i].showRate = lst[i].normalRate; //合格率
            lst[i].showTimes = lst[i].normalTimes;
            break;
          case 1:
            lst[i].showRate = lst[i].abnormalRate; //漏检率
            lst[i].showTimes = lst[i].abnormalTimes;
            break;
        }
      }
    },

    Query() {
      let p = null;
      if (
        this.$route.params.p != undefined &&
        this.$route.params.p.pagesize != 1
      )
        p = this.$route.params.p;
      if (!p) p = getSessionObj("Query_Params");
      if (!p) {
        //this.showHint = true;
        return;
      }

      let that = this;
      that.loading = true;

      GetUnusual(
        {
          token: this.$store.getters.getToken,
          startTime: p.mDate1,
          endTime: p.mDate2,
          strCompanyIds: p.mCompany,
          strDepartmentIds: p.mDept,
          strRouteIds: p.mLine,
          strPlaceIds: p.mAddr,
          strGroupIds: p.mGroup,
          strInspectorIds: p.mUser,
          queryType: p.mState,
          page: this.page,
          limit: p.pagesize,
        },
        (ret) => {
          that.loading = false;
          if (ret && ret.code == 0) {
            if (ret.count == 0) {
              that.finished = true;
              //that.showHint = false;
            } else {
              //填充
              let tmpDate = ret.data;
              for (let j = 0; j < tmpDate.length; j++) {
                tmpDate[j].showRate = tmpDate[j].normalRate;
                tmpDate[j].showTimes = tmpDate[j].normalTimes;
              }
              that.lstData.push(...tmpDate);
              //that.lstData.push(...ret.data);
              //this.showHint = that.lstData.length == 0;

              //判断是否全部加载完成
              if (that.lstData.length < ret.count) {
                that.page = that.page + 1;
                that.finished = false;
              } else {
                that.finished = true;
                //that.showHint = false;
              }
            }
          } else {
            that.finished = true;
            this.$Toast(!ret ? this.$t('lang.queryfail') : ret.message);
          }
        }
      );
    },

    onLoad() {
      this.Query();
    },
  },
};
</script>
